// Import packages
import React from "react";
import {connect} from 'react-redux';

// Import assets
import './services.scss';
import {Dots} from "../../../assets/images";

// Import components

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {services} from "../../../constants/staticData";

const Services = (props) => {
    const {staticTexts} = props;

    return <section className={`services-wrapper`} id={'services'}>
        <div className="service-title">{staticTexts?.services_title}</div>
        <div className="services-content">
            {
                services?.map((item, index) => {
                    return <a href={item.link ? navigator?.platform !== 'MacIntel' && navigator?.platform !== 'iPhone'
                        ? 'https://play.google.com/store/apps/details?id=am.nikita.fortunecup&hl=en&gl=AM'
                        : 'https://apps.apple.com/us/app/fortune-cup/id982142045' : "##"}
                              target={item.link ? '_blank' : ''}
                        className="service-item"   >
                        <div  className={'link'} key={item.id}>
                        <div className="service-item-title">{staticTexts?.[item.title]}</div>
                        <div className="service-img-wrapper">
                            <Dots className={'dots-icon'} title={''}/>
                            <img src={item.icon} alt="" className='service-item-icon'/>
                        </div>
                    </div>
                    </a>
                })
            }
        </div>
    </section>
};

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Services);