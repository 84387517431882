import {GENERAL_CONSTS} from "../constants";
import {StaticTexts} from "../../constants/staticTexts";
import {hy_img} from "../../assets/images";

export const initialState = {
    languages: [],
    selectedLanguage: {
        "code": "hy",
        "name": "Հայ",
        "isMain": true,
        "icon": hy_img,
    },
    // translation: StaticTexts.hy,
    staticTexts: StaticTexts.hy,
    contacts: {},
    stations: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        /*case GENERAL_CONSTS.GET_LANGUAGES:
            const mainLanguage = action.payload.find(lg => lg.isMain);
            const selectedLanguageIsExist = state.selectedLanguage && state.selectedLanguage.id
                && action.payload.find(lg => lg.code === state.selectedLanguage.code);
            const newSelectedLanguage = selectedLanguageIsExist ? state.selectedLanguage :
                (mainLanguage ? mainLanguage : action.payload[0]);

            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };
            // if (state.staticTexts && state.staticTexts[newSelectedLanguage.code]) {
            //     newState.translation = state.staticTexts[newSelectedLanguage.code]
            // }
            return newState;*/
        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                staticTexts: action.payload[state.selectedLanguage?.code] || StaticTexts[state.selectedLanguage?.code]
            };
        case GENERAL_CONSTS.GET_CONTACTS:
            return {
                ...state,
                contacts: action.payload
            };
        case GENERAL_CONSTS.GET_STATIONS:
            return {
                ...state,
                stations: action.payload
            };
        default:
            return state;
    }
}
