// import packages
import React from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

//import assets
import "./header.scss";
import {apple_store, google_play, project_logo} from "../../assets/images";
import {ReactComponent as PhoneIcon} from "../../assets/images/icons/ic_phone.svg";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage} from "../../redux/actions";

function Header(props) {
    const {contacts} = props;
    const location = useLocation();
    console.log(props?.staticTexts?.menu_item_main,'STATIC')

    return <div className={`header-wrapper `}>
        {contacts?.phoneNumber && <a href={`tel:${contacts?.phoneNumber}`} className="phone_btn">
            <div className="service-center">
                <PhoneIcon title='' className={'service-center-icon'}/>
                <span>{props.staticTexts?.service_center}</span>
            </div>
        </a>}

        <section className={`header-box-wrapper`}>
            <a href={'/#main'} className="header-logo">
                <img src={project_logo} alt=""/>
            </a>

            <div className="nav-bar">
                <a className={`nav-item ${location.hash === '#main' ? 'active' : ''}`}
                   href={'/#main'}>{props?.staticTexts?.menu_item_main}</a>
                <a className={`nav-item ${location.hash === '#services' ? 'active' : ''}`}
                   href={'/#services'}>{props?.staticTexts?.menu_item_services}</a>
                <a className={`nav-item ${location.hash === '#about' ? 'active' : ''}`}
                   href={'/#about'}>{props?.staticTexts?.menu_item_about}</a>
                <a className={`nav-item ${location.hash === '#app' ? 'active' : ''}`}
                   href={'/#app'}>{props?.staticTexts?.menu_item_app}</a>
                <div className={`apps-wrapper`}>
                    <a href="https://play.google.com/store/apps/details?id=am.nikita.fortunecup&hl=en&gl=AM"
                       className={'google-play'}
                       target={'_blank'}>
                        <img src={google_play} alt=""/>
                    </a>
                    <a href="https://apps.apple.com/us/app/fortune-cup/id982142045"
                       target={'_blank'}>
                        <img src={apple_store} alt=""/>
                    </a>
                </div>
            </div>
        </section>

        {/*Mobile Menu*/}

        <div className="mobile-nav-bar">
            <a href={'/#main'}>
                <img src={project_logo} className={'mobile-logo'} alt=""/>
            </a>
            <div className={`nav-bar-menu ${props.mobileMenuIsOpen ? 'active' : ''}`}
                 onClick={props.toggleMobileMenu}>
                <span className="line line-1"/>
                <span className="line line-2"/>
                <span className="line line-3"/>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'contacts',
    ])
};

const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
