//----------------------------------4Steps-server---------------------------------------------------
// const ADMIN_URL = "http://173.249.20.192:6020/api/v1";
// const HOST_MEDIA_URL = "http://173.249.20.192:6013";
// const USERS_URL =  "http://173.249.20.192:6011/api/v1";

//----------------------------------Official-server---------------------------------------------------
const ADMIN_URL =  "https://app.fortunecup.am/admin/api/v1";
const USERS_URL =  "https://app.fortunecup.am/users/api/v1";
const HOST_MEDIA_URL = "https://app.fortunecup.am/resources";

export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlContacts = USERS_URL + "/contacts";
export const _urlDiviner = USERS_URL + '/users/diviner'
export const _hostMedia = HOST_MEDIA_URL;