// import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Slider from "react-slick";

//import assets
import "./app.scss";
import {app_bg, mockup, navbar, HoverNav} from "../../../assets/images";

// Import Components
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {ProgressBar} from "../../uiElements/progress-bar/ProgressBar";

// Import utils
import {app} from "../../../constants/staticData";

class App extends Component {
    constructor() {
        super();
        this.state = {
            slideIndex: 0,
            itemHeight: 0,
            sliderCircleEnded: false,
            firstImageLoaded: false,
            loadedImg: false
        };
        this.pagination = React.createRef();
        this.paginationItem = React.createRef();
        this.scrollList = React.createRef();
        this.img = React.createRef();
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    componentDidMount() {
        const hoveredItems = ['item_1', 'item_2', 'item_3', 'item_4', 'item_5',]
        hoveredItems?.map(item => {
            document.getElementById(item)?.addEventListener("click", this.onClick.bind(this, item))
        })
        document.getElementById(`bg_${this.state.slideIndex + 1}`).style.visibility = "visible";
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.slideIndex !== prevState.slideIndex) {
            document.getElementById(`bg_${prevState.slideIndex + 1}`).style.visibility = "hidden";
            document.getElementById(`bg_${this.state.slideIndex + 1}`).style.visibility = "visible";
        }
    }

    onClick(item) {
        const index = item.slice(-1) - 1
        this.getSliderIndex(index)
    }

    getSliderIndex = (index) => {
        this.setState({
            slideIndex: index,
        });
        this.slider.slickGoTo(index);
    };

    nextSlide = (index) => {
        this.setState({
            slideIndex: this.state.slideIndex - 1 === index ? 0 : index + 1,
        });
        if (app?.length - 1 === this.state.slideIndex) {
            this.setState({
                slideIndex: 0,
            });
        }
        this.slider.slickNext();
    };

    prevSlide = (index) => {
        this.setState({
            slideIndex: index === 0 ? app?.length - 1 : index - 1,
        });
        this.slider.slickPrev();
    };

    afterChangeSlideIndex = (slideIndex) => {
        this.setState({
            sliderCircleEnded: false,
        })
    };

    beforeChangeSlideIndex = (_, slideIndex) => {
        this.setState({
            slideIndex,
            sliderCircleEnded: true,
        })
    };

    handleImageLoaded() {
        if (!this.state.loadedImg) {
            this.setState({loadedImg: true});
        }
    }

    render() {
        const {slideIndex, sliderCircleEnded} = this.state;
        const {staticTexts} = this.props;

        const settingsSlider = {
            dots: false,
            arrows: false,
            infinite: true,
            autoplaySpeed: 5000,
            autoplay: true,
            pauseOnHover: false,
            // lazyLoad: 'progressive',
            afterChange: this.afterChangeSlideIndex,
            beforeChange: this.beforeChangeSlideIndex,
            ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
            // fade: true
        };

        return !!app?.length && <section className="app-wrapper" id='app'>
            <div className="left-side">
                <Slider {...settingsSlider} className="slider-wrapper">
                    {app?.map((item, index) => {
                        return <div key={item.id} className={`slider-item`} ref={this.img}>
                            <div className="sub-slide-title">
                                {staticTexts.app_title}
                            </div>
                            <div className="slide-title">{staticTexts?.[item?.title]}</div>
                            <div className="slide-description">{staticTexts?.[item?.description]}</div>
                            <div className="slide-content">
                                <div className="col">
                                    <img src={item?.icon_1} alt=""/>
                                    <div className="col-title">{staticTexts?.[item?.sub_title_1]}</div>
                                    <div className="col-description">{staticTexts?.[item?.sub_description_1]}</div>
                                </div>
                                <div className="col">
                                    <img src={item?.icon_2} alt=""/>
                                    <div className="col-title">{staticTexts?.[item?.sub_title_2]}</div>
                                    <div className="col-description">{staticTexts?.[item?.sub_description_2]}</div>
                                </div>
                            </div>
                        </div>
                    })}
                </Slider>
                <ProgressBar slideIndex={slideIndex}
                             sliderCircleEnded={sliderCircleEnded}
                             sliderLenght={app?.length}/>
            </div>
            <div className="right-side">
                <img src={app_bg} alt="" className="app-bg"/>
                <div className="mockup-wrapper">
                    <div className="image-proportion">
                        <img src={mockup} alt="" className="mockup"/>
                        <div className="navbar_bg">
                            <img src={navbar} alt="" className=""/>
                        </div>
                        <img src={app[slideIndex]?.app_bg} alt="" className="home-bg"/>
                        <HoverNav className="hover_nav"/>
                    </div>
                </div>
            </div>
        </section>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
