export const StaticTexts = {
    en: {
        menu_item_main: "Home",
        menu_item_services: "Services",
        menu_item_app: "App",
        menu_item_about: "About",
        service_center: "+374 98 988 977",
        mobile_menu_language: "Language",
        main_title: "Museums as Social Harmony Factors",
        main_description: "Artistry in the time of wireless and globalisation Throughout artists of all disciplines have needed friends and allies to get pieces performed, be it Haydn and.",
        main_btn: "Download",
        services_title: "Our Service",
        service_1_item_title: "Help always need",
        service_2_item_title: "Draw the cup for guess",
        service_3_item_title: "Learn more with message",
        service_4_item_title: "Create and manage an account",
        about_img_title: "Our mission",
        about_img_description: "I have paved new ways for the Armenian art, by opening forbidde",
        about_sub_title: "About us",
        about_title: "About us",
        about_description: "The “Fortune cup” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden do dividual worldview and artistic self-expression under the Soviet me. The “Galentz Museum”s established due to the joint efforts of Haro\nGalentz on his motherland,self-expression under the Soviet me. Galentz’s family – wife, artist Armine Galentz, and sons Saro and Ar dividual worldview and artistic self-expression under the Soviet me. Galentzs, whose private collections set basis for the museum exhibiti. self-expression under the Soviet me.",
        about_btn: "Learn More",
        app_title: "App",
        app_home_title: "The application created for ios and android platforms",
        app_home_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_home_sub_title_1: "Capture now",
        app_home_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_home_sub_title_2: "Download from gallery",
        app_home_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_notification_title: "The application created for ios and android platforms",
        app_notification_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_notification_sub_title_1: "Capture now",
        app_notification_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_notification_sub_title_2: "Download from gallery",
        app_notification_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_photo_title: "The application created for ios and android platforms",
        app_photo_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_photo_sub_title_1: "Capture now",
        app_photo_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_photo_sub_title_2: "Download from gallery",
        app_photo_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_help_title: "The application created for ios and android platforms",
        app_help_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_help_sub_title_1: "Capture now",
        app_help_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_help_sub_title_2: "Download from gallery",
        app_help_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_account_title: "The application created for ios and android platforms",
        app_account_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_account_sub_title_1: "Capture now",
        app_account_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_account_sub_title_2: "Download from gallery",
        app_account_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        info_title: "Subscribe to us",
        info_description: "The death of the renowned painter Haroutiun Fortune Cup",
        footer_description: "The “Fortune Cup” opened its doors on April 27, 2010,",
        footer_menu_title: "Company",
        footer_contacts_title: "Company",
        footer_social_title: "Follow us",
        footer_section_copyright_text: "© Copyright 2021 Fortune Cup All rights reserved.",
        footer_section_developed_by_text: "Development",
        diviner_title:"Դառնալ գուշակ-en",
        success_title_text:"Հայտը հաջողությամբ ուղարկվեց",
        error_title_text:"Ինչ որ բան այն չէ",
        send_btn:"Send",
        full_name_label:"Name, Surname",
        email_label:"E-mail",
        number_label:" Phone number",
    },
    hy: {
        menu_item_main: "Home",
        menu_item_services: "Services",
        menu_item_app: "App",
        menu_item_about: "About",
        service_center: "+374 98 988 977",
        mobile_menu_language: "Language",
        main_title: "Museums as Social Harmony Factors",
        main_description: "Artistry in the time of wireless and globalisation Throughout artists of all disciplines have needed friends and allies to get pieces performed, be it Haydn and.",
        main_btn: "Download",
        services_title: "Our Service",
        service_1_item_title: "Help always need",
        service_2_item_title: "Draw the cup for guess",
        service_3_item_title: "Learn more with message",
        service_4_item_title: "Create and manage an account",
        about_img_title: "Our mission",
        about_img_description: "I have paved new ways for the Armenian art, by opening forbidde",
        about_sub_title: "About us",
        about_title: "About us",
        about_description: "The “Fortune cup” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden do dividual worldview and artistic self-expression under the Soviet me. The “Galentz Museum”s established due to the joint efforts of Haro\nGalentz on his motherland,self-expression under the Soviet me. Galentz’s family – wife, artist Armine Galentz, and sons Saro and Ar dividual worldview and artistic self-expression under the Soviet me. Galentzs, whose private collections set basis for the museum exhibiti. self-expression under the Soviet me.",
        about_btn: "Learn More",
        app_title: "App",
        app_home_title: "The application created for ios and android platforms",
        app_home_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_home_sub_title_1: "Capture now",
        app_home_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_home_sub_title_2: "Download from gallery",
        app_home_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_notification_title: "The application created for ios and android platforms",
        app_notification_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_notification_sub_title_1: "Capture now",
        app_notification_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_notification_sub_title_2: "Download from gallery",
        app_notification_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_photo_title: "The application created for ios and android platforms",
        app_photo_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_photo_sub_title_1: "Capture now",
        app_photo_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_photo_sub_title_2: "Download from gallery",
        app_photo_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_help_title: "The application created for ios and android platforms",
        app_help_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_help_sub_title_1: "Capture now",
        app_help_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_help_sub_title_2: "Download from gallery",
        app_help_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_account_title: "The application created for ios and android platforms",
        app_account_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_account_sub_title_1: "Capture now",
        app_account_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_account_sub_title_2: "Download from gallery",
        app_account_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        info_title: "Subscribe to us",
        info_description: "The death of the renowned painter Haroutiun Fortune Cup",
        footer_description: "The “Fortune Cup” opened its doors on April 27, 2010,",
        footer_menu_title: "Company",
        footer_contacts_title: "Company",
        footer_terms_and_conditions:"Terms and Conditions",
        footer_social_title: "Follow us",
        footer_section_copyright_text: "© Copyright 2021 Fortune Cup All rights reserved.",
        footer_section_developed_by_text: "Development",
        diviner_title:"Դառնալ գուշակ",
        success_title_text:"Հայտը հաջողությամբ ուղարկվեց",
        error_title_text:"Ինչ որ բան այն չէ",
        send_btn:"Send",
        full_name_label:"Name, Surname",
        email_label:"E-mail",
        number_label:" Phone number",
    },
    ru: {
        menu_item_main: "Home",
        menu_item_services: "Services",
        menu_item_app: "App",
        menu_item_about: "About",
        service_center: "+374 98 988 977",
        mobile_menu_language: "Language",
        main_title: "Museums as Social Harmony Factors",
        main_description: "Artistry in the time of wireless and globalisation Throughout artists of all disciplines have needed friends and allies to get pieces performed, be it Haydn and.",
        main_btn: "Download",
        services_title: "Our Service",
        service_1_item_title: "Help always need",
        service_2_item_title: "Draw the cup for guess",
        service_3_item_title: "Learn more with message",
        service_4_item_title: "Create and manage an account",
        about_img_title: "Our mission",
        about_img_description: "I have paved new ways for the Armenian art, by opening forbidde",
        about_sub_title: "About us",
        about_title: "About us",
        about_description: "The “Fortune cup” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden do dividual worldview and artistic self-expression under the Soviet me. The “Galentz Museum”s established due to the joint efforts of Haro\nGalentz on his motherland,self-expression under the Soviet me. Galentz’s family – wife, artist Armine Galentz, and sons Saro and Ar dividual worldview and artistic self-expression under the Soviet me. Galentzs, whose private collections set basis for the museum exhibiti. self-expression under the Soviet me.",
        about_btn: "Learn More",
        app_title: "App",
        app_home_title: "The application created for ios and android platforms",
        app_home_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_home_sub_title_1: "Capture now",
        app_home_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_home_sub_title_2: "Download from gallery",
        app_home_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_notification_title: "The application created for ios and android platforms",
        app_notification_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_notification_sub_title_1: "Capture now",
        app_notification_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_notification_sub_title_2: "Download from gallery",
        app_notification_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_photo_title: "The application created for ios and android platforms",
        app_photo_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_photo_sub_title_1: "Capture now",
        app_photo_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_photo_sub_title_2: "Download from gallery",
        app_photo_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_help_title: "The application created for ios and android platforms",
        app_help_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_help_sub_title_1: "Capture now",
        app_help_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_help_sub_title_2: "Download from gallery",
        app_help_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        app_account_title: "The application created for ios and android platforms",
        app_account_description: "The “Galentz Museum” opened its doors on April 27, 2010, 40 years the death of the renowned painter Haroutiun Galentz. Galentz’s wor have paved new ways for the Armenian art, by opening forbidden.",
        app_account_sub_title_1: "Capture now",
        app_account_sub_description_1: "Dividual worldview and artistic selfexpr ession under the.",
        app_account_sub_title_2: "Download from gallery",
        app_account_sub_description_2: "Harout dividual worldview and artiic self-expression unde.",
        info_title: "Subscribe to us",
        info_description: "The death of the renowned painter Haroutiun Fortune Cup",
        footer_description: "The “Fortune Cup” opened its doors on April 27, 2010,",
        footer_menu_title: "Company",
        footer_contacts_title: "Company",
        footer_social_title: "Follow us",
        footer_section_copyright_text: "© Copyright 2021 Fortune Cup All rights reserved.",
        footer_section_developed_by_text: "Development",
        diviner_title:"Դառնալ գուշակ-ru",
        success_title_text:"Հայտը հաջողությամբ ուղարկվեց",
        error_title_text:"Ինչ որ բան այն չէ",
        send_btn:"Send",
        full_name_label:"Name, Surname",
        email_label:"E-mail",
        number_label:" Phone number",
    }
};
