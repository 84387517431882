import React, {useState} from "react";

import {Modal} from "antd";
import "./fortuneModel.scss"
import {InputGroup} from "../uiElements/input-group/inputGrup";
import isEmail from "validator/es/lib/isEmail";
import {REQUEST_TYPE} from "../../constants/defaultTexts";
import {SendDiviner} from "../../redux/actions";
import {connect} from "react-redux";
import {getPropsFromState} from "../../redux/mapStateToProps";


function FortuneModel (props) {
    const {value,setValue,staticTexts} = props
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmBtnDisable, setConfirmBtnDisable] = useState(false);
    const [modalTitleType, setModalTitleType] = useState("");
    const [fieldsData, setFieldsData] = useState({
        fullName: "",
        phone: "",
        email: "",
    })
    const [errors, setErrors] = useState({
        fullName: "",
        phone: "",
        email: "",
    })

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {

            if ((key === 'email' && !isEmail(fieldsData[key])) || (key === 'phone' && fieldsData[key]?.length < 8)
                || (key === 'fullName' && fieldsData[key]?.length === 0)) {
                errors[key] = true;
                result = false;
            }

        })
        setErrors(errors);
        return result;
    }

    function sendCallRequest() {
        if(validate()){
            setConfirmLoading(true);
            let reqData = fieldsData
            props.SendDiviner(reqData).then(() =>{
                setTimeout(() => {
                    setConfirmLoading(false);
                    setModalTitleType(REQUEST_TYPE.success);
                    setConfirmBtnDisable(true)
                }, 2000)
                setTimeout(() => {
                    closeModal()
                    setConfirmLoading(false);
                    setModalTitleType(REQUEST_TYPE.title);
                    setConfirmBtnDisable(false)
                }, 3000)
            }).catch((err) => {
                setConfirmLoading(false);
            })
        }
    }

    function closeModal(){
        setValue(false)
        setFieldsData({
            fullName: "",
            phone: "",
            email: "",
        })
        setErrors({
            fullName: "",
            phone: "",
            email: "",
        })
    }

    function getInputValues({name, value}) {
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: false,
        })
    }

    const text = modalTitleType === REQUEST_TYPE.success
        ? staticTexts?.success_title_text : (modalTitleType === REQUEST_TYPE.error
            ? staticTexts?.error_title_text : staticTexts?.diviner_title)
    return <Modal centered
        visible={value}
        title={text}
        okText={staticTexts?.send_btn}
        confirmLoading={confirmLoading}
        onOk={!confirmBtnDisable && sendCallRequest}
        onCancel={closeModal}>

        <div className={'inputs-block'}>
            <div className={'topBlock'}>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.fullName}
                    error={errors.fullName}
                    name={'fullName'}
                    maxLength={100}
                    label={staticTexts?.full_name_label}
                    onChange={getInputValues}
                />
            </div>
            <div className={'bottomBlock'}>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.phone}
                    error={errors.phone}
                    name={'phone'}
                    label={staticTexts?.number_label}
                    onChange={getInputValues}
                />

                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.email}
                    error={errors.email}
                    name={'email'}
                    label={staticTexts?.email_label}
                    onChange={getInputValues}
                />
            </div>
        </div>

    </Modal>

}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
const mapDispatchToProps = {
    SendDiviner,
};

export default connect(mapStateToProps, mapDispatchToProps)(FortuneModel);