// import packages
import React from "react";
import {connect} from "react-redux";

//import assets
import "./about.scss";
import {about_bg} from "../../../assets/images";


// Import Components
import {getPropsFromState} from "../../../redux/mapStateToProps";


const About = (props) => {
    const {staticTexts} = props;

    return <section className={`about-wrapper`} id='about'>
        <div className="left-side">
            <div className="image-wrapper">
                <img className="about-img" src={about_bg}/>
            </div>
            <div className="img-description-wrapper">
                <div className="img-title">{staticTexts?.about_img_title}</div>
                <div className="img-description">{staticTexts?.about_img_description}</div>
            </div>
        </div>
        <div className="right-side">
            <div className="about-sub-title">{staticTexts?.about_sub_title}</div>
            <div className="about-title">{staticTexts?.about_title}</div>
            <div className="about-description">{staticTexts?.about_description}</div>
            <button className="about-btn">{staticTexts?.about_btn}</button>
        </div>
    </section>
};

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
