// Import packages
import React, {memo} from "react";
import {connect} from 'react-redux';

// Import assets
import './mobile-menu.scss';
import {google_play, apple_store} from "../../assets/images";
import {ReactComponent as PhoneIcon} from "../../assets/images/icons/ic_phone.svg";

// Import components
import {Menu, Dropdown} from 'antd';

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage} from "../../redux/actions";
import {languages} from "../../constants/staticData";

const MobileMenu = memo((props) => {
    const {
        className, toggleMobileMenu, selectedLanguage, contacts
    } = props;


    const menuLanguage = (
        <Menu className="languages-dropdown">
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.code}
                                   onClick={() => {
                                       props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            {item.name}
                        </Menu.Item>
                })
            }
        </Menu>
    );

    return <section className={`mobile-menu-wrapper ${className}`}>
        <div className={`mobile-menu-content ${className}`}>
            <div className="nav-bar">
                <a className="nav-item" href={'/#main'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_main}</a>
                <a className="nav-item" href={'/#services'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_services}</a>
                <a className="nav-item" href={'/#about'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_about}</a>
                <a className="nav-item" href={'/#apps'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_app}</a>
            </div>
            {/*<div className="languages-wrapper">*/}
            {/*    <span>{props?.staticTexts?.mobile_menu_language}</span>*/}
            {/*    <Dropdown overlay={menuLanguage}*/}
            {/*              className="language-button"*/}
            {/*              overlayClassName={''}*/}
            {/*              placement="bottomCenter"*/}
            {/*              size={'small'}>*/}
            {/*        <div className="dropdown-menu-item">*/}
            {/*            {selectedLanguage?.name}*/}
            {/*        </div>*/}
            {/*    </Dropdown>*/}
            {/*</div>*/}
            <a href={`tel:${contacts?.phoneNumber}`} className="phone-link">
                <div className="service-center">
                    <PhoneIcon title='' className={'service-center-icon'}/>
                    <span>{props.staticTexts?.service_center}</span>
                </div>
            </ a>
        </div>
        <div className={`mobile-menu-overlay`} onClick={toggleMobileMenu}/>

    </section>
});

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = {
    ChangeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);