import {_hostMedia} from "../redux/api";
import defaultLogo from '../assets/images/default_img.jpg';

export function generateImageMediaUrl(path, imgFormat) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${_hostMedia}/images${path}` : defaultLogo;
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? `${_hostMedia}/files${path}` : defaultLogo;
}