// Import packages
import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

//Import styles
import "./layout.scss";

// Import components
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import MobileMenu from "../../components/mobile-menu/MobileMenu";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetStaticTexts} from "../../redux/actions";
import MetaTags from "../../components/MetaTags";

class Layout extends Component {
    constructor() {
        super();
        this.state = {
            mobileMenuIsOpen: false,
        };
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    }

    componentDidMount() {
        // this.props.GetLanguages();
        this.props.GetStaticTexts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location, selectedLanguage} = this.props;
        if (location?.pathname !== prevProps?.location?.pathname) {
            window.scrollTo(0, 0);
            this.setState({
                mobileMenuIsOpen: false,
            })
        }
        if (selectedLanguage && prevProps.selectedLanguage && selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetStaticTexts();
        }
    }

    toggleMobileMenu() {
        this.setState({
            mobileMenuIsOpen: !this.state.mobileMenuIsOpen,
        })
    }

    render() {
        const {staticTextsList, children} = this.props;
        console.log(children,'children')
        const {mobileMenuIsOpen} = this.state;

        return <div className="layout-wrapper">
            <MetaTags/>
            <Header
                mobileMenuIsOpen={mobileMenuIsOpen}
                toggleMobileMenu={this.toggleMobileMenu}
            />
            <div className={`main-content`}>
                {children}
            </div>
            <Footer staticTextsList={staticTextsList}/>
            <MobileMenu
                toggleMobileMenu={this.toggleMobileMenu}
                className={mobileMenuIsOpen ? 'expand_on' : ''}
            />
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts'
    ])
};

const mapDispatchToProps = {
    // GetLanguages,
    GetStaticTexts
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
