import React from 'react';
import {getPropsFromState} from "../redux/mapStateToProps";
import {connect} from "react-redux";


function PrivacyPolicy() {
    return <div className='privacy-policy-container'>
        <h3>
            TERMS OF USE
        </h3>
        <p>
            <span>
                Welcome to Fortune Cup application (Application). You will not need to visit a
            fortune teller anymore if you use this Application. Now you have a fortune teller in
            your mobile device. The Application allows You to receive a fortune prediction on
            the grounds of Your cup of coffee.
            The following Terms of Use (Terms) regulate the Application download and usage
            procedure, the rules and terms, as well as the relations between the Application rights
            holder (Holder) and User (You and all the forms of its conjugation).
            The Holder is the natural and/or legal person who arranges the rights to use the
            Application in all forms that do not violate the legislation of the Republic of Armenia,
            international conventions and agreements. By accepting these Terms You confirm that You
            have all the rights, legal capacities and competence necessary and sufficient to use this
            Application.
            </span>
            <h2>DOWNLOADING THE APPLICATION</h2>
            <ul>
            <li>
                The Application is available for downloading at Google Play and App Store
                The Application is free.
            </li>
            <li>
                The cost of Internet traffic used when downloading the Application is paid
                according to Your current tariffs.
            </li>
            <li>
                The Application can be downloaded only to mobile phones with Internet access run on
                Android
            </li>
                <li>
                    2.2 and later versions or iOs operating systems.
                </li>
            </ul>
            <h2>
                USING THE APPLICATION
            </h2>
            <ul>
            <li>
                When sending the photographs of a coffee cup via the Application for the first
                time, user You will be asked to read the Terms and accept them.
            </li>
            <li>
                Accepting means that You accept the Terms unconditionally, as well as certify that
                You are at
                least 15 years old.
            </li>
            <li>
                You cannot use the service without accepting the Terms.
            </li>
            <li>
                The Settings section of the application allows You to fill in some personal information
                (full name, e-mail address, gender, age, date and time of birth, marital status etc.)
                The information will be used for more accurate predictions and provide alternative
                options to contact You.
            </li>
            <li>
                By using the Application You certify that You agree to receive general
                announcements,
                notifications, messages containing company information, advertisement and
                information from the Holder via Notification section of the Application, on Your
                mobile phone number, and/or e-mail
                •To use the Application more easily, you can open a personal account. You can
                open a personal account just taping on the button “Account” and filling out the
                requested data and creating login and password. You can delete your personal
                account at any time just taping the button “deactivate account”.
            </li>
           <li>
               When filling in the required information, You will be asked to provide only accurate and
               complete
               information.
           </li>
            </ul>
            <h2>SENDING/RECIEVEING PREDICTION ORDERS</h2>
            <ul>
                <li>
                    After enjoying a cup of coffee take the photograph of the mug from 3 different
                    positions, so that the inner part of the cup is visible:
                </li>
            <li>
                Position the cup in a way that its handle is on the right
            </li>
            <li>
                Position the cup in a way that its handle is on the left
            </li>
            <li>
                Take a photo of the cup from above after pinning the grounds on the bottom of the
                cup with Your middle finger.
            </li>
            <li>Choose a fortune teller from the list</li>
            <li>Accept Terms</li>
            <li>Send the photos of the cup to the selected fortune teller.</li>
            <li>After sending the photographs, a short message will be generated automatically from</li>
            <li>Your mobile phone number.</li>
            </ul>
            <h2> PAYMENT</h2>
            <ul>
            <li>
                The information about the fees for the message will be displayed on Your screen,
                allowing to confirm or decline the order.
            </li>
            <li>
                You can choose payment methods before sending prediction order.
            </li>
           <li>
               You will be able to pay for the prediction order by In App Purchase, SMS or Promo
               Code.
           </li>
            <li>
                If SMS payment is available in your country you will see an SMS payment option
                before sending the prediction order.
            </li>
            </ul>
            <h2>ADDITIONAL INFORMATION</h2>
            <ul>
            <li>
                The green sign next to a fortune teller's name indicates that the person is online.
            </li>
            <li>
                The icon next to the foreteller's name indicates the language/s, on which the fortune
                teller is able to provide the prediction .
            </li>
            <li>
                The Country from which the fortune teller is can be found next to the fortune teller’s
                name
            </li>
            <li>
                The list of languages available in the account settings of the Application will allow
            </li>
            <li>
                You to select a preferred language for the Application interface.
            </li>
            <li>
                The country of Your current location will be detected automatically by Application.
            </li>
            <li>
                The Application's default language is English.
            </li>
            <li>
                The fortune tellers are registered individuals, and their predictions are not automated.
            </li>
            <li>
                If the quality of the photographs sent to a fortune teller is low, or if the photographs
                have inappropriate content, You will receive a message notifying that the Prediction
                cannot be completed. The fee for the message You have sent will not be refunded.
            </li>
           <li>
               Predictions will be sent to You within 24 hours, after your prediction order is confirmed.
           </li>
            <li>
                You'll receive the prediction in the "Notifications" section of the Application of
                which You will be informed by “Push out” signal.
            </li>
            <li>
                If the prediction is not delivered to Your phone number due to technical issues, it may
                be sent to
            </li>
            <li>
                Your e-mail address.
            </li>
            <li>
                The message fee does not include the fees for Internet traffic, when using the
                Application.
            </li>
            <li>
                The Application is unavailable when roaming is activated.
            </li>
            </ul>
            <h2>
                RESPONSIBILITIES
            </h2>
            <span>
                The Holder does not edit or monitor in other ways the predictions made by the fortune
            tellers. Therefore, the Holder does not bear any responsibility for the content and the
            accuracy of the predictions.
            Please note that You use the Application solely at Your own risk, will and means, and
            the Holder does not bear any responsibility for the damage caused to You or to a third party
            by Your or a third party's actions or inactions, as well as for the consequences of your
            actions related to the Application.
            The relations emerged while using the Application are regulated by the legislation of the
            Republic of Armenia, in case of conflicts only the courts of Republic of Armenia are
            recognized as competent.
            </span>
            <h2>OTHER TERMS</h2>
            <span>
                The Holder has the right to make changes to these Terms at any time without prior notice.
            The Holder does not bear any responsibility for temporary interruption of Application's
            use due to technical or any other reasons.
            In case of problems when using the Application You can contact the Holder's customer
            service center by the following phone number: +374 10 512 888.
            </span>
        </p>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['staticTexts'])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);