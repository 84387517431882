// import packages
import React from "react";
import {connect} from "react-redux";

//import assets
import "./main.scss";
import {main_bg} from "../../../assets/images";

// Import Components
import {getPropsFromState} from "../../../redux/mapStateToProps";

// Import utils


const Main = (props) => {
    const {staticTexts} = props;

    return <section className={`main-wrapper`} id={'main'}>
        <div className="main-content">
            <div className="main-title">{staticTexts?.main_title}</div>
            <div className="main-description">
                {staticTexts?.main_description}
            </div>
            <a href={navigator?.platform !== 'MacIntel' && navigator?.platform !== 'iPhone'
                ? 'https://play.google.com/store/apps/details?id=am.nikita.fortunecup&hl=en&gl=AM'
                : 'https://apps.apple.com/us/app/fortune-cup/id982142045'}
               target={'_blank'}
               className="download-btn">
                {staticTexts?.main_btn}
            </a>
        </div>
        <div className={`main-img`}>
            <img src={main_bg} alt={''}/>
            <div className="box"/>
        </div>
    </section>
};

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts'
    ])
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
