// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import Components;
import App from "../components/homepage/app/App";
import Services from "../components/homepage/services/Services";
import Main from "../components/homepage/main/Main";
import About from "../components/homepage/about/About";
import Info from "../components/homepage/info/Info";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";

class Homepage extends Component {
    render() {
        const {staticTexts} = this.props;
        return <div className="homepage-wrapper">
            <Main/>
            <Services/>
            <About/>
            <App/>
            <Info staticTexts={staticTexts}/>
        </div>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, ['staticTexts'])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
