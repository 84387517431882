import React, {useState} from "react";
import {Select} from "antd";

//Import Assets
import "./input-group.scss";

const {Option} = Select;

export function InputGroup(props) {
    const {
        label, type, inputType, name, className, placeholder, error, autocomplete = "on",
        onKeyDown, onKeyUp, id, defValue, initValue, value, maxLength,
        autoFocus, resizable, disabled, forwardRef, integerNumber, maxValue, minValue,
        required, number, regExp, errorMsg
    } = props;
    const [focused , setFocused] = useState(false);

    function onChange(e) {
        let {name, value} = e.target;
        if (disabled) {
            return;
        }
        // console.log('Values', value)
        if (number && (isNaN(Number(value)) || value?.split('.')?.[1]?.length > 2)) {
            return;
        }
        if (value && integerNumber) {
            value = parseInt(value)
        }
        if ((integerNumber || number)) {
            if (minValue && Number(value) < minValue && value !== '') {
                return;
            }
            if (maxValue && Number(value) > maxValue && value !== '') {
                return;
            }
        } else {
            value = value.trimStart();
        }
        // console.log('Values', initValue, value, initValue.toString() !== value.toString());
        if (regExp && value && !regExp.test(value)) {
            return;
        }
        props.onChange({
            name,
            value,
            haveChanges: (!initValue && value) || initValue?.toString() !== value?.toString()
        });
    }


    function onFocus() {
        props.onFocus && props.onFocus();
        setFocused(true)
    }
    function onBlur() {
        props.onFocus && props.onBlur();
        setFocused(false)
    }

    return <div className={`custom-input-group ${focused || value ? 'active' : ''}`} id={name}>

        {label && <label>{label} {required ? <span className={'required-badge'}>*</span> : ''}</label>}
        {inputType === "input" && type !== "number" && type !== "checkbox" && type !== "password" &&
            <>
                <input type={type} name={name} placeholder={placeholder} maxLength={maxLength} ref={forwardRef}
                       value={value || ''} defaultValue={defValue} onChange={onChange} autoComplete={'off'}
                       autoFocus={autoFocus} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onBlur={onBlur} onFocus={onFocus}
                       disabled={!!disabled} id={id}
                       className={`${className} ${error ? "invalid" : ""}`}/>
                {props.children}
                {errorMsg && <div className={`error-message ${!!errorMsg ? 'show' : 'hide'}`}>
                    {errorMsg}
                </div>}
            </>}

        {inputType === "input" && type === "password" &&
            <>
                <input type={type} name={name} placeholder={placeholder} maxLength={maxLength}
                       value={value || ''} defaultValue={defValue} onChange={onChange}
                       autoFocus={autoFocus} autoComplete={autocomplete} onKeyDown={onKeyDown} onKeyUp={onKeyUp}
                       onBlur={onBlur} onFocus={onFocus} className={`${className} ${error ? "invalid" : ""}`}/>
                {props.children}
                {errorMsg && <div className={`error-message ${!!errorMsg ? 'show' : 'hide'}`}>
                    {errorMsg}
                </div>}
            </>}

        {inputType === "input" && type === "number" &&
            <input type={'text'} name={name} placeholder={placeholder} ref={forwardRef}
                   value={value || ''} onChange={onChange} autoComplete={'off'}
                   autoFocus={autoFocus} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onBlur={onBlur} onFocus={onFocus}
                   disabled={!!disabled} id={id} required={required}
                   className={`${className} ${error ? "invalid" : ""}`}/>}
        {inputType === "number" &&
            <input type={'number'} name={name} placeholder={placeholder} ref={forwardRef} pattern="\d*"
                   value={value || ''} onChange={onChange} autoComplete={'off'}
                   autoFocus={autoFocus} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onBlur={onBlur}
                   disabled={!!disabled} id={id} required={required}
                   className={`${className} ${error ? "invalid" : ""}`}/>}

        {inputType === "input" && type === "checkbox" &&
            <input type={type} name={name} checked={value} onChange={onChange}/>}

        {inputType === "textarea" &&
            <textarea name={name} placeholder={placeholder} value={value || ''}
                      defaultValue={defValue} onChange={onChange} maxLength={maxLength}
                      className={`${className} ${error ? "invalid" : ""} ${resizable ? "resizable" : ""}`}/>}
        {inputType === "wrapper" &&
            <> {props.children}</>
        }
    </div>
}