import React from "react";
import {store} from "../redux/store";

import {
    home_svg_1, home_svg_2, notif_svg_1, notif_svg_2, photo_svg_1, photo_svg_2, help_svg_1, help_svg_2,
    account_svg_1, account_svg_2, Service_1, Service_2, Service_3, Service_4, home_bg,
    photo_bg, notif_bg, account_bg, en_img, hy_img, ru_img, help_bg, service_1,download_for, service_2, service_3, service_4
} from "../assets/images";

/*
*
*Languages
*
* */

export const languages = [
    {
        "code": "hy",
        "name": "Հայ",
        "isMain": true,
        "icon": hy_img,
    },
    {
        "code": "en",
        "name": "Eng",
        "isMain": false,
        "icon": en_img,
    },
    {
        "code": "ru",
        "name": "Ру",
        "isMain": false,
        "icon": ru_img,
    }
];

/*
*
* Slider Data
*
* */

export const app = [
    {
        "id": "5f4e59b5bcfd6a78dbca162b",
        "title": 'app_home_title',
        "description": 'app_home_description',
        "icon_1": home_svg_1,
        "sub_title_1": 'app_home_sub_title_1',
        "sub_description_1": 'app_home_sub_description_1',
        "icon_2": home_svg_2,
        "sub_title_2": 'app_home_sub_title_2',
        "sub_description_2": 'app_home_sub_description_2',
        "app_bg": home_bg,
    },
    {
        "id": "5f4e59bsds4d6a78dbca162b",
        "title": 'app_notification_title',
        "description": 'app_notification_description',
        "icon_1": notif_svg_1,
        "sub_title_1": 'app_notification_sub_title_1',
        "sub_description_1": 'app_notification_sub_description_1',
        "icon_2": notif_svg_2,
        "sub_title_2": 'app_notification_sub_title_2',
        "sub_description_2": 'app_notification_sub_description_2',
        "app_bg": notif_bg,
    },
    {
        "id": "5f4e59bsds4d6a78dbca162b",
        "title": 'app_photo_title',
        "description": 'app_photo_description',
        "icon_1": photo_svg_1,
        "sub_title_1": 'app_photo_sub_title_1',
        "sub_description_1": 'app_photo_sub_description_1',
        "icon_2": photo_svg_2,
        "sub_title_2": 'app_photo_sub_title_2',
        "sub_description_2": 'app_photo_sub_description_2',
        "app_bg": photo_bg,
    },
    {
        "id": "5f4e59bsds4d6a78dbca162b",
        "title": 'app_help_title',
        "description": 'app_help_description',
        "icon_1": help_svg_1,
        "sub_title_1": 'app_help_sub_title_1',
        "sub_description_1": 'app_help_sub_description_1',
        "icon_2": help_svg_2,
        "sub_title_2": 'app_help_sub_title_2',
        "sub_description_2": 'app_help_sub_description_2',
        "app_bg": help_bg,
    },
    {
        "id": "5f4e59bsds4d6a78dbca162b",
        "title": 'app_account_title',
        "description": 'app_account_description',
        "icon_1": account_svg_1,
        "sub_title_1": 'app_account_sub_title_1',
        "sub_description_1": 'app_account_sub_description_1',
        "icon_2": account_svg_2,
        "sub_title_2": 'app_account_sub_title_2',
        "sub_description_2": 'app_account_sub_description_2',
        "app_bg": account_bg,
    },
];

/*
*
* Services
*
* */
export const services = [
    {
            "id": "5f4e5f9b5bdfs6a78dbca162b",
            "title": 'service_1_item_title',
            "icon": service_1,
            "link": false,
        },
        {
            "id": "5f6385a7895eb5a50ctycd8e55",
            "title": 'service_4_item_title',
            "icon": download_for,
            "link":true,
        },
        {
            "id": "5f6385a78s95eb5a50ccd8e55",
            "title": 'service_2_item_title',
            "icon": service_2,
            "link": false,
        },
        {
            "id": "5f6385a7895eb5a50cercd8e55",
            "title": 'service_3_item_title',
            "icon": service_3,
            "link": false,
        },

];
