// import packages
import React, {useState} from "react";

//Import styles
import "./info.scss";
import {apple_store, google_play, info_img} from "../../../assets/images";
import FortuneModel from "../../fortuneModel/FortuneModel";

//Import Components

// Import utils

const Info = (props) => {
    const {staticTexts} = props;
    const [value,setValue] = useState(false)

    function toggleFortuneBtn(){
        setValue(!value)
    }

    return <div className="info-wrapper">
        <div className="left-part">
            <img src={info_img} alt=""/>
        </div>
        <div className="right-part">
            <div className="info-title">{staticTexts?.info_title}</div>
            <div className="info-description">{staticTexts?.info_description}</div>

            <button className='fortuneBtn' onClick={() => setValue(true)}>
                {staticTexts?.diviner_title}
            </button>

            <FortuneModel value={value}
                          setValue={setValue}/>
        </div>
    </div>
}

export default Info;
