// Import packages
import React, {Component} from "react";
import {Route, Switch, Router} from "react-router-dom";

// Import utils
import {history} from "../configs/history";

// Import pages
import Layout from "../containers/layout/Layout";
import Homepage from "../containers/Homepage";
import PrivacyPolicy from "../containers/PrivacyPolicy"

export default  class Routes extends Component {
    render() {

        return <Router history={history}>
            <Layout>
                <Switch>
                    <Route exact path="/" component={Homepage}/>
                    <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                </Switch>
            </Layout>
        </Router>
    }
}
